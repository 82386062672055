import React, { useEffect } from "react";
import { Chart } from "react-google-charts";



export default function GenderData({ areaName,totalCount,genderData }) {
  
  // const data = [
  //   ["Cinsiyet", "Sayı", { role: "style" }],
  //   ["Erkek", 75, "blue"], 
  //   ["Kadın", 64, "pink"], 
  // ];


  const data = [
    ["Cinsiyet", "Sayı", { role: "style" }],
    ...genderData.map(item => [
        item.gender === "male" ? "Erkek" : "Kadın",
        item.count,
        item.gender === "male" ? "blue" : "pink"
    ])
];
  const options = {
    title: areaName + " Cinsiyet Bilgileri",
    legend: { position: "none" },
    hAxis: {
      title: "Cinsiyet",
    },
    vAxis: {
      title: "Sayı",
      format: '0',  // Sayıları tamsayı olarak göster
      viewWindow: {
        min: 0,
      },
    },
  };
  return (
    <>
    <div
        style={{ textAlign: "center", marginTop: "10px", fontWeight: "bold" }}
      >
        Toplam Başvuru: {totalCount}
      </div>
      {
        genderData.length ? <Chart
        chartType="ColumnChart"
        width="auto"
        height="400px"
        options={options}
        data={data}
      /> : "Yükleniyor..."
      }
      
    </>
  );
}
