import React from "react";
import { Chart } from "react-google-charts";





export default function SectionData({areaName,uniData}) {

  const data = [
    ["Bölüm Adı", "Başvuru Sayısı"],
    ...(uniData && uniData.length > 0
      ? uniData.map((item) => [item.sectionName, item.count])
      : [["Veri Yok", 0]] 
    ),
  ];

  const options = {
    title: areaName + " Üniversite Bölüm Bilgileri",
    is3D: true,
    chartArea: { left: 10, top: 30, right: 10, bottom: 0 }
  };

  return (
    <>
    {
      uniData.length ? 
      <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width="auto"
      height={"400px"}
    />
    : "Yükleniyor..."
    }
    </>
    
  );
}
