import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MyScreen = ({ onLogin }) => {
  const navigate = useNavigate(); // Yönlendirme için useNavigate'i tanımla
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    // Local storage'dan token'ı kontrol et
    const token = localStorage.getItem("token");
    if (token) {
      // Eğer token varsa, kullanıcıyı /detail sayfasına yönlendir
      navigate("/detail");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      email: email,
      password: password,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://vizyonergenc.com/api/v2/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const token = response.data.access_token;
        localStorage.setItem("token", token);
        onLogin();
        navigate("/detail");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h1 style={styles.title}>Login</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputContainer}>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputContainer}>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.button}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f2f5",
  },
  formContainer: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "400px",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    color: "#007bff",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginTop: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  button: {
    padding: "10px 0",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "10px",
  },
  // Media Queries for responsive design
  "@media(max-width: 600px)": {
    formContainer: {
      padding: "10px",
    },
    input: {
      fontSize: "14px",
      padding: "8px",
    },
    button: {
      fontSize: "14px",
      padding: "8px 0",
    },
  },
};
export default MyScreen;
