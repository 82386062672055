import React, { useEffect, useState } from "react";
import logo from "./assets/ssa_myh.png";
import "./style.css";
import Area from "./Area";
import logo_101 from "./assets/101.png";
import logo_102 from "./assets/102.png";
import logo_401 from "./assets/401.png";
import logo_402 from "./assets/402.png";
import axios from "axios";
const Detail = () => {

  const [data101, setData101] = useState({});
  const [data102, setData102] = useState({});
  const [data401, setData401] = useState({});
  const [data402, setData402] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token')
    const id_101 = 3117 
    const id_401 = 3118 
    const id_102 = 3119 
    const id_402 = 3120 
    let config_101 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://vizyonergenc.com/api/v2/company/get-detailed-application-statistics-for-101-and-401/' + id_101,
      headers: { 
        'Authorization': 'Bearer ' + token, 
      }
    };
    let config_401 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://vizyonergenc.com/api/v2/company/get-detailed-application-statistics-for-101-and-401/' + id_401,
      headers: { 
        'Authorization': 'Bearer ' + token, 
      }
    };
    let config_102 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://vizyonergenc.com/api/v2/company/get-detailed-application-statistics-for-102-and-402/' + id_102,
      headers: { 
        'Authorization': 'Bearer ' + token, 
      }
    };
    let config_402 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://vizyonergenc.com/api/v2/company/get-detailed-application-statistics-for-102-and-402/' + id_402,
      headers: { 
        'Authorization': 'Bearer ' + token, 
      }
    };
    
    axios.request(config_101)
    .then((response) => {
      setData101(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
    axios.request(config_102)
    .then((response) => {
      setData102(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
    axios.request(config_401)
    .then((response) => {
      setData401(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
    axios.request(config_402)
    .then((response) => {
      setData402(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
    axios.get("http://localhost:3000/data").then((response)=>{
      console.log(response.data)
      setData101(response.data)
    }).catch((err)=>{
      console.log(err)
    })
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <img src={logo} alt="logo" />
      </div>
      <Area
        areaName={"Savunma 101"}
        areaLogo={logo_101}
        areaColor={"#394949"}
        data={data101}
      ></Area>
      <Area
        areaName={"Savunma 401"}
        areaLogo={logo_401}
        areaColor={"#9f3b36"}
        data={data401}
      ></Area>
      <Area
        areaName={"Savunma 102"}
        areaLogo={logo_102}
        areaColor={"#007780"}
        data={data102}
      ></Area>
      <Area
        areaName={"Savunma 402"}
        areaLogo={logo_402}
        areaColor={"#4f664e"}
        data={data402}
      ></Area>
    </>
  );
};

export default Detail;
