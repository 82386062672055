import React from "react";
import { Chart } from "react-google-charts";

export default function UniversityData({ areaName, top5Data }) {
  const data = [
    ["Üniversite Adı", "Başvuru Sayısı"],
    ...(top5Data && top5Data.length > 0
      ? top5Data.map((item) => [item.university_name, item.application_count])
      : [["Veri Yok", 0]] // Boşsa alternatif bir veri ekle
    ),
  ];
  const options = {
    title: areaName + " Üniversite Bilgileri",
    pieHole: 0.4,
    is3D: false,
    chartArea: { left: 10, top: 30, right: 10, bottom: 0 },
  };
  return (
    <>
      {top5Data.length ? (
        <Chart
          chartType="PieChart"
          width="auto"
          height="400px"
          data={data}
          options={options}
        />
      ) : (
        "Yükleniyor..."
      )}
    </>
  );
}
