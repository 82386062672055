import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Detail from "./Detail";
import Login from "./Login"; // Login sayfası

function App() {

    // Kullanıcının giriş yapıp yapmadığını takip eden state
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const login = () => {
      const token = localStorage.getItem('token')
      if(token){
        return true
      }else{
        return false
      }
    }
    // Kullanıcı giriş yaptıktan sonra çağırılacak fonksiyon
    const handleLogin = () => {
      setIsLoggedIn(true);
      console.log("here")
    };
  
    // PrivateRoute bileşeni (giriş yapılmadıysa yönlendirme yapacak)
    const PrivateRoute = ({ children }) => {
      return login ? children : <Navigate to="/" />;
    };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} /> {/* Giriş Sayfası */}
        <Route 
          path="/detail" 
          element={
            <PrivateRoute>
              <Detail />
            </PrivateRoute>
          } 
        /> {/* Özel Route: Giriş yapılmadıysa Detail sayfasına erişilemez */}
      </Routes>
    </Router>
  );
}

export default App;
