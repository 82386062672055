import { useEffect, useState } from "react";
import GenderData from "./GenderData";
import SectionData from "./SectionData";
import UniversityData from "./UniversityData";

export default function Area({ areaName, areaLogo, areaColor, data }) {

  const [totalCount, setTotalCount] = useState(0)
  const [genderData, setGenderData] = useState([])
  const [top5Data, setTop5Data] = useState([])
  const [uniData, setUniData] = useState([])
  useEffect(() => {
    setTotalCount(data.generalStatistics ? data.generalStatistics.total_applications : 0 )
    setGenderData(data.generalStatistics ? data.generalStatistics.gender_count : [])
    setTop5Data(data.generalStatistics ? data.generalStatistics.top_5_university_applications : [])
    setUniData(data.sectionApplications ? data.sectionApplications : [])
  }, [data])
  


  const containerStyle = {
    border: `1.5px solid ${areaColor}`,
  };
  return (
    <div style={{ padding: "20px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <img
          src={areaLogo}
          alt="logo"
          width="50"
          style={{ marginRight: "20px" }}
        />
        <h1 style={{ color: areaColor }}>{areaName}</h1>
      </div>
      <div className="grid-container">
        <div className="grid-item" style={containerStyle}>
          <GenderData areaName={areaName} totalCount={totalCount} genderData={genderData}></GenderData>
        </div>
        <div className="grid-item" style={containerStyle} >
          <UniversityData areaName={areaName} top5Data={top5Data}></UniversityData>
        </div>
        <div className="grid-item" style={containerStyle} >
          <SectionData areaName={areaName} uniData={uniData}></SectionData>
        </div>
      </div>
    </div>
  );
}
